import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import ResizeCalc from "../common/ResizeCalc";

const Header = () => {
  const [marginTopHeader, setMarginTopHeader] = useState(0);
  const [heightHeader, setHeightHeader] = useState(0);

  function resizeMarginTop(width) {
    if (width>=992 && width<=1439) {
      let minScreenMarginTop = 992;
      let maxElementMarginTop = 240;
      let minElementMarginTop = 175;
      if (width>=1200) {
        minScreenMarginTop = 1200;
        maxElementMarginTop = 208;
        minElementMarginTop = 150;
      }
      const resultCalcMarginTop = ResizeCalc(
        width,
        1439,
        minScreenMarginTop,
        maxElementMarginTop,
        minElementMarginTop
      );
      setMarginTopHeader(resultCalcMarginTop)
    }
  }

  function resizeHeightHeader(width) {
    if (width>=576 && width<=1439) {
      const resultCalcHeightHeader = ResizeCalc(width, 1440, 576, 1176, 470);
      setHeightHeader(`${resultCalcHeightHeader}px`);
    }
  }

  useEffect(() => {
    const handlerResize = (event) => {
      const width = window.document.documentElement.clientWidth;
      resizeMarginTop(width);
      resizeHeightHeader(width)
    }

    handlerResize()
    window.addEventListener("resize", handlerResize);

    return () => window.removeEventListener("resize", handlerResize);
  }, []);

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "header-background.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      fluid={imageData}
      id="header"
      style={ heightHeader ? { height: `${heightHeader}` } : {}}
    >
      <div className="container-flexy">
        <div className="row no-gutters">
          <div className="col-5 header-text d-none d-lg-block" style={{top: `${marginTopHeader}px`}}>
            <div className="wrap-h1">
              <h1 className="px-0">Instantly turn your wall into a photo album</h1>
            </div>
            <div className="wrap-p">
              <p>And keep it fresh as you easily change your photos as often as you like.</p>
            </div>
          </div>
        </div>
      </div>
      </BackgroundImage>
  )
}



export default Header;