import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import OnFocus from '../common/OnFocus';
import ValidateEmail from '../common/ValidateEmail';
import { ToastContainer, toast } from 'react-toastify';
import addToMailchimp from "gatsby-plugin-mailchimp"
import scrollTo from 'gatsby-plugin-smoothscroll';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: ''
  });
  const [formDataError, setFormDataError] = useState({
    name: '',
    email: ''
  });
  const [checkedColours, setCheckedColours] = useState({});
  const [checkedSizes, setCheckedSizes] = useState({});

  const checkboxColoursList = [
    {
      id: "black",
      label: "Black"
    }, {
      id: "white",
      label: "White"
    }, {
      id: "purple",
      label: "Purple"
    }, {
      id: "yellow",
      label: "Yellow"
    }, {
      id: "red",
      label: "Red"
    }, {
      id: "blue",
      label: "Blue"
    }, {
      id: "green",
      label: "Green"
    }
  ];

  const checkboxSizesList = [
    {
      id: "Large",
      label: "Extra large 650mm x 1200mm (holds 48 photos)"
    },
    {
      id: "Square",
      label: "Square 650mm x 650mm (holds 32 photos)"
    },
    {
      id: "Tall strip",
      label: "Tall strip 300mm x 800mm (holds 24 photos)"
    },
  ];

  const refName = useRef();
  const refEmail = useRef();
  const focusedName = OnFocus(refName);
  const focusedEmail = OnFocus(refEmail);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  }

  const handleCheckBoxChange = event => {
    const { name, checked } = event.target;
    setCheckedColours({ ...checkedColours, [name]: checked });
  };

  const handleChangeSizes = event => {
    setSize(event.target.name);
  };

  const setSize = (name) => {
    const checked = checkedSizes[name] ? false : true;
    setCheckedSizes({ ...checkedSizes, [name]: checked });
  }

  async function handleFormSubmit(event) {
    event.preventDefault();

    const { name, email } = formData;

    const fieldError = {
      name: '',
      email: ''
    };
    let setError = 0;

    if (name==="") {
      setError = 1;
      fieldError.name = "Name is required";
    }

    if (email==="") {
      setError = 1;
      fieldError.email = "E-mail is required";
    } else {
      if (!ValidateEmail(email)) {
        setError = 1;
        fieldError.email = "E-mail is invalid";
      }
    }

    setFormDataError({ ...formDataError, ...fieldError });

    const colours = checkboxColoursList
      .filter(item => checkedColours[item.id])
      .map(item => item.label);

    const sizes = checkboxSizesList
      .filter(item => checkedSizes[item.id])
      .map(item => item.id);

    if (setError) {
      toast.error('Please check the indicated fields and fix any errors before continuing.');
      scrollTo('#contact');
    } else {
      await addToMailchimp(email, {
        NAME: name,
        COLOURS: colours.join(', '),
        SIZE: sizes.join(', ')
      });
      toast.success('Thank you for subscribing!');
      setFormData({
        name: '',
        email: ''
      });
      setCheckedColours({});
      setCheckedSizes({});
    }
  }

  return (
    <section id="contact">
      <div className="container-1200">
        <h2 className="text-center text-lg-left">Want us to let you know when<br/>FlexyFoto is ready?</h2>
        <div className="wrap-p">
          <p className="text-center text-lg-left mt-2">The first FlexyFoto albums are due to be available in early 2021. Initially, we will produce a 24 photo (long strip) version in black and one other colour.</p>
          <p className="text-center text-lg-left mt-2">If you would like us to let you know when FlexyFoto is ready for ordering online, please submit your details below and we will send you a notification.</p>
        </div>

        <form onSubmit={handleFormSubmit} method="post">
          <div className="row mt-3 no-gutters">
            <div className="col-12">
              <div className={classNames('input-field-flexy', {
              'input-field-flexy-error': formDataError.name!==''
              })}>
                <input type="text" ref={refName} name="name" className="" value={formData.name} onChange={handleInputChange} autoComplete="off"/>
                <label htmlFor="name" className={classNames({
                  'active': focusedName || formData.name!==''
                  })}>Your name</label>
                {formDataError.name!=='' && <small>{formDataError.name}</small>}
              </div>
            </div>

            <div className="col-12">
              <div className={classNames('input-field-flexy', {
                'input-field-flexy-error': formDataError.email!==''
              })}>
                <input type="text" ref={refEmail} name="email" className="" value={formData.email} onChange={handleInputChange} autoComplete="off"/>
                <label htmlFor="email" className={classNames({
                  'active': focusedEmail || formData.email!==''
                  })}>Email address</label>
                {formDataError.email!=='' && <small>{formDataError.email}</small>}
              </div>
            </div>
          </div>

          <h3 className="text-center text-lg-left mb-3 margin-color-title">
              Which colours would you be insterested in?{" "}
              <small className="text-muted">(Optional)</small>
          </h3>

          <div className="d-flex flex-wrap colour-select-list">
            {checkboxColoursList.map(colour => (
              <div className={classNames('pr-4', {
                'pl-0 pl-lg-2': colour.id!=='black',
              })} key={colour.id}>
                <div className="flexy-checkbox d-inline-flex align-items-center flex-column mb-3">
                  <input type="checkbox" id={colour.id} name={colour.id} className="checked d-none" checked={checkedColours[colour.id] ? true : false} onChange={handleCheckBoxChange}/>
                  {/* eslint jsx-a11y/label-has-associated-control: off */}
                  <label htmlFor={`${colour.id}`} className={`${colour.id} rounded-circle`}/>
                  <p>{colour.label}</p>
                </div>
              </div>
            ))}
          </div>

          <h3 className="text-center text-lg-left mb-3 margin-color-title">
              Which FlexyFoto size would you be interested in?{" "}
              <small className="text-muted">(Optional)</small>
          </h3>

          <div className="d-flex flex-wrap justify-content-center justify-content-lg-start">
            <div className="d-flex flex-column pb-1">
              {checkboxSizesList.map(size => (
                <div className="flexy-radio-size d-flex align-items-center py-2" key={size.id}>
                  <input type="checkbox" className="d-none checked" checked={checkedSizes[size.id] ? true : false} id={size.id} name={size.id} onChange={handleChangeSizes}/>
                  {/* eslint jsx-a11y/label-has-associated-control: off */}
                  <label htmlFor={size.id} className="rounded-circle"/>
                  <button type="button" onClick={() => setSize(size.id)} className="flexy-radio-size-text border-0 bg-white">
                    {size.label}
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="text-center margin-color-title">
            <button type="submit" className="btn btn-flexy" title="Join our waiting list">
              Submit
            </button>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={false}
      />
    </section>
  );
}

export default Contact;