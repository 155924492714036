import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Product = () => {
  const { flexyProductImage } = useStaticQuery(graphql`
    query {
      flexyProductImage: file(relativePath: { eq: "flexy-product.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <section className="section-product">
      <div className="container-flexy">
        <div className="row no-gutters">
          <div className="col-12 col-lg-4 col-xl-4 order-lg-2 text-center text-lg-left col-text d-flex flex-column align-self-center margin-mobile">
            <div className="wrap-h2">
              <h2>Main features</h2>
            </div>
            <div className="wrap-p">
              <p>Holds 24 standard photos (6”x4”)</p>
              <p>Photos slide in from the front so they can quickly &amp; easily be removed and replaces</p>
              <p>Rolls flat and attaches to the wall using two-sided damage free adhesive</p>
              <p>Comes in several colours (black and one other colour to start) </p>
            </div>
          </div>
          <div className="col-12 col-lg-7 col-xl-8 order-lg-1 text-center text-lg-left">
            <div className="image-centre w-90">
              <Img
                fluid={flexyProductImage.childImageSharp.fluid}
                alt="Flexyfoto details box"
                className="image-centre"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Product;
