import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Photos = () => {
  const { flexySample } = useStaticQuery(graphql`
  query {
    flexySample: file(relativePath: { eq: "flexy-sample.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`);

  return (
    <section className="section-photos">
      <div className="row no-gutters justify-content-center">
        <div className="col-lg-7 col-10">
          <h2 className="text-center text-lg-left d-block d-lg-block">
            Instantly change your photos...<br/>anytime
          </h2>
          <Img
            fluid={flexySample.childImageSharp.fluid}
            alt="Flexyfoto sample"
            className="image-centre"
          />
        </div>
      </div>
    </section>
  );
}

export default Photos;