import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";
import NavBarLogo from "../_molecules/NavBarLogo";
import "./style.scss";

const NavBarPrimary = () => {
  const [scrollHeader, setScrollHeader] = useState(false);


  useEffect(() => {
    const handlerHeader = event => {
      const currentScrollY = window.scrollY;
      setScrollHeader(parseInt(currentScrollY, 10) > 116);
    };

    window.addEventListener("scroll", handlerHeader);

    return () => window.removeEventListener("scroll", handlerHeader);
  }, []);

  return (
    <>
      <nav
        id="NavBarPrimary"
        className={`row no-gutters d-none d-lg-flex ${
          scrollHeader ? "sticky-top" : "position-absolute"
        }`}
      >
        <div className="col-6 d-flex align-items-center px-0">
          <Link to="/">
            <NavBarLogo className="d-none-absolute" />
            <NavBarLogo className="d-none-sticky" isSticky={true} />
          </Link>
        </div>
        <div className="col-6 d-flex align-items-center justify-content-end px-0">
          <button
            onClick={() => scrollTo("#contact")}
            className="btn btn-flexy"
          >
            Notify me
          </button>
        </div>
      </nav>

      <nav
        id="NavBarMobilePrimary"
        className="d-block d-lg-none bg-white text-center mt-3 pt-3 pb-5"
      >
        <Link to="/">
          <NavBarLogo />
        </Link>
        <div className="margin-mobile">
          <h2 className="mt-4 pt-5">
            Instantly turn your wall into a photo album
          </h2>
          <div className="my-3">
            <p>
              And keep it fresh as you easily change your photos as often as you
              like.
            </p>
          </div>
        </div>
        <button onClick={() => scrollTo("#contact")} className="btn btn-flexy">
          Notify me
        </button>
      </nav>
    </>
  );
};

export default NavBarPrimary;
