import React from "react";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

import Layout from "../components/common/Layout";
import NavBarPrimary from "../components/NavBarPrimary";
import Header from "../components/sections/Header";
import DoMore from "../components/sections/DoMore";
import Attach from "../components/sections/Attach";
import Slide from "../components/sections/Slide";
import Photos from "../components/sections/Photos";
import Product from "../components/sections/Product";
import Contact from "../components/sections/Contact";
import Footer from "../components/sections/Footer";

library.add(fab)

const Home = () => (
  <Layout>
    <NavBarPrimary />
    <Header />
      <main id="main">
        <DoMore />
        <Attach />
        <Slide />
        <Photos />
        <Product />
        <Contact />
      </main>
    <Footer />
  </Layout>
);

export default Home;
