import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import ResizeCalc from "../common/ResizeCalc";

const Attach = () => {
  const [heightHeader, setHeightHeader] = useState(0);
  const [marginTopHeader, setMarginTopHeader] = useState(0);
  const [backgroundSize, setBackgroundSize] = useState(0);
  const [backgroundPosition, setBackgroundPosition] = useState(0);

  useEffect(() => {
    const handlerResize = (event) => {
      const width = window.document.documentElement.clientWidth;

      setBackgroundSize(`100% 100%`);
      setBackgroundPosition(`0% 0%`);

      if (width<=1439) {
        if (width>=992) {
          const heightScreen = ResizeCalc(width, 1439, 992, 848, 584);
          setHeightHeader(`${heightScreen}px`);

          const topHeightMargin = ResizeCalc(width, 1439, 992, 245, 140);
          setMarginTopHeader(`${topHeightMargin}px`);

        } else {
          setMarginTopHeader('70px');

          const heightScreen =  ResizeCalc(width, 991, 320, 1220, 640);
          setHeightHeader(`${heightScreen}px`);

          const backgroundWidthScreen = ResizeCalc(width, 991, 320, 1870, 555);
          const backgroundHeightScreen = ResizeCalc(width, 991, 320, 1101, 327);
          setBackgroundSize(`${backgroundWidthScreen}px ${backgroundHeightScreen}px`);

          const positionWidthScreen = ResizeCalc(width, 991, 320, 700, 194);
          setBackgroundPosition(`${(positionWidthScreen*-1)}px 100%`);
        }
      }
    }

    handlerResize()
    window.addEventListener("resize", handlerResize);

    return () => window.removeEventListener("resize", handlerResize);
  }, []);

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "flexy-attach-background.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      fluid={imageData}
      id="attach"
      style={ heightHeader ? {
          height: `${heightHeader}`,
          backgroundSize: `${backgroundSize}`,
          backgroundPosition: `${backgroundPosition}`
        } : {}}
    >
      <div className="container-flexy">
        <div className="row no-gutters">
          <div className="col-12 col-lg-4 d-flex flex-column align-self-top-center align-self-lg-center text-center text-lg-left pr-0 position-relative margin-mobile">
            <div className="attach-h2" style={ heightHeader ? { marginTop: `${marginTopHeader}` } : {}}>
              <h2>Attach FlexyFoto to your wall</h2>
            </div>
            <div className="attach-p">
              <p>FlexyFoto easily sticks to your walls using non-damaging two-sides adhesive tape.</p>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
};

export default Attach;