const ResizeCalc = (currentWidth, maxScreen, minScreen, maxElement, minElement) => {
  const differenceScreen = maxScreen - minScreen;
  const differenceElement = maxElement - minElement;
  const differenceWidth = maxScreen - currentWidth;
  const difference = differenceScreen - differenceWidth;
  const ratio = difference * (differenceElement / differenceScreen);
  return minElement + ratio;
}

export default ResizeCalc;
