import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const DoMore = () => {
  const { flexyDoMoreImage } = useStaticQuery(graphql`
    query {
      flexyDoMoreImage: file(relativePath: { eq: "flexy-do-more.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <section className="heading">
      <div className="row no-gutters">
        <div className="col-12 col-lg-4 d-flex flex-column align-self-center order-lg-2 text-center text-lg-left col-text margin-mobile">
          <div className="wrap-h2">
            <h2>Do more with your photos</h2>
          </div>
          <div className="wrap-p">
            <p>Take your memories and put them on the wall in just 5 minutes. And as you create new memories refresh your FlexyFoto album in just seconds.</p>
            <p>Ideal for living rooms, children’s rooms, hallways and more, FlexyFoto is the perfect way to turn empty walls into photo albums that bring a smile to your day, each and every day.</p>
          </div>
        </div>
        <div className="col-12 col-lg-7 order-lg-1 text-center text-lg-center align-image">
          <Img
            fluid={flexyDoMoreImage.childImageSharp.fluid}
            alt="Do more with your photos"
          />
        </div>
      </div>
    </section>
  );
}
export default DoMore;