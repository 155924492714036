import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import ResizeCalc from "../common/ResizeCalc";

export default function Slide() {
  const [heightHeader, setHeightHeader] = useState(0);

  useEffect(() => {
    const handlerResize = (event) => {
      const width = window.document.documentElement.clientWidth;

      if (width>=320 && width<=1439) {
        let maxScreen = 1439;
        let minScreen = 320;
        let maxElement = 756;
        let minElement = 165;

        if (width<=991) {
          maxScreen = 991;
          minScreen = 320;
          maxElement = 900;
          minElement = 270;
        }

        const heightScreen = ResizeCalc (width, maxScreen, minScreen, maxElement, minElement);
        setHeightHeader(`${heightScreen}px`);
      }
    }

    handlerResize()
    window.addEventListener("resize", handlerResize);

    return () => window.removeEventListener("resize", handlerResize);
  }, []);

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "flexy-slide.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const imageData = data.desktop.childImageSharp.fluid

  return (
    <>
      <section className="heading section-slide-header d-lg-none">
        <div className="row no-gutters">
          <div className="col-12 d-flex flex-column align-self-center text-center col-text margin-mobile">
            <h2 className="text-white">Slide in your favourite photos</h2>
            <p className="text-white">Photos simply slide in out of your mounted FlexyFoto without having to remove your album from the wall.</p>
          </div>
        </div>
      </section>
      <BackgroundImage Tag="section" fluid={imageData} id="section-slide" style={
        heightHeader
        ? {
          height: `${heightHeader}`,
        } : {}
      }>
        <div className="container-flexy">
          <div className="row no-gutters">
            <div className="col-12 col-lg-4 offset-lg-7 text-lg-left col-text d-none d-lg-flex flex-column align-self-top-center align-self-lg-center text-center pr-0">
              <div className="wrap-h2">
                <h2 className="text-white">Slide in your favourite photos</h2>
              </div>
              <div className="wrap-p">
                <p className="text-white">Photos simply slide in out of your mounted FlexyFoto without having to remove your album from the wall.</p>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </>
  );
}
